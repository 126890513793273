/* eslint-disable jsx-a11y/anchor-has-content */
import * as React from 'react'
import styled from 'styled-components'
import { useLink } from '@bestyled/contrib-common'
import mediaqueries from '../styles/media'

const Button = styled((props) => {
  const { Link } = useLink()

  let { to } = props

  if (
    typeof window !== 'undefined' &&
    window.location &&
    props.to &&
    props.withParams
  ) {
    if (window.location.search.match(/[?&]s=\w\w(?:&|$)/)) {
      let params = window.location.search.replace(/^\?/, '').split('&')
      let state = params.find((parma) => parma.startsWith('s=')).split('=')[1]
      let specialtyFilter = ''

      if (window.location.search.match(/[?&]surveytype=/)) {
      let surveyType = params.find((parma) => parma.startsWith('surveytype=')).split('=')[1]
      let surveyScore = parseInt( params.find((parma) => parma.startsWith('surveyscore=')).split('=')[1])
      // look for optional surveyType and results
      if (surveyType === 'ISI') {
         // check the corresponding scoring value
         if (surveyScore > 14)
         {
          specialtyFilter = 'sleep'
         }
      }
    }
      if (state === 'US' || state.length !== 2) {
        state = ''
      }
      params = params.filter((parma) => !parma.startsWith('s='))
      const search = params.join('&')
      to = `${to.replace(/\/$/, '')}/${state}/${specialtyFilter}?${search}`
    }
  }

  return props.href ? <a {...props} /> : <Link {...props} to={to} />
})`
  font-family: ${(p) => p.theme.fonts.menu};
  text-transform: ${(p) => p.theme.textTransforms.button};
  letter-spacing: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 161px;
  height: 38px;
  border: 1px solid
    ${(p) =>
      p.theme.colors.buttonBg === 'transparent'
        ? p.theme.colors.brand
        : p.theme.colors.buttonBg};
  color: ${(p) => p.theme.colors.button};
  background: ${(p) => p.theme.colors.buttonBg};
  font-weight: 600;
  border-radius: ${(p) => p.theme.borderRadius.button};
  transition: border-color 0.2s var(--ease-in-out-quad),
    background 0.2s var(--ease-in-out-quad), color 0.2s var(--ease-in-out-quad);
  &:hover {
    background: ${(p) => p.theme.colors.buttonHover};
    color: ${(p) => p.theme.colors.buttonHoverBg};
    border-color: ${(p) => p.theme.colors.buttonHoverBg};
  }
  &[disabled] {
    cursor: not-allowed;
  }
  svg * {
    fill: ${(p) => p.theme.colors.brandBg};
  }
  ${(p) => mediaqueries.tablet`
    position: relative;
    height: 30px;
    width: 100%;
    top: 0;
    left: 0;
    border: 1px solid
    ${(p) =>
      p.theme.colors.buttonBg === 'transparent'
        ? p.theme.colors.brand
        : p.theme.colors.buttonBg};
  color: ${(p) => p.theme.colors.button};
  background: ${(p) => p.theme.colors.buttonBg};
  font-weight: 600;
  border-radius: ${(p) => p.theme.borderRadius.button};
  transition: border-color 0.2s var(--ease-in-out-quad),
    background 0.2s var(--ease-in-out-quad), color 0.2s var(--ease-in-out-quad);
  &:hover {
    background: ${(p) => p.theme.colors.buttonHover};
    color: ${(p) => p.theme.colors.buttonHoverBg};
    border-color: ${(p) => p.theme.colors.buttonHoverBg};
  }
  &[disabled] {
    cursor: not-allowed;
  }
  svg * {
    fill: ${(p) => p.theme.colors.brandBg};
  }
  `}
`

export default Button
